import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import AuthContext from "../UaaPage/login/components/AuthContext";
import style from "./styles/GroupBuy.module.css";
import "./styles/GroupBuy.css";
import "./styles/AdvertisingPage.css";
import "./styles/antStyle.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../Components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import popupStyle from "../Components/styles/popup.module.css";
import LoginParent from "../UaaPage/login/LoginParent";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckMark from "./components/CheckMark";
// import PopupBanner from '../UaaPage/login/imgs/PopupBanner.png';
// import gbBannerTopPhone1 from './imgs/gb_banner_top_phone.gif';
// import gbBannerTopPhone2 from './imgs/gb_banner_top_phone_2.gif';
// import gbAdd2cartPC from './imgs/gb_add2cart_phone.webp';
// import gbAdd2cartPhone from './imgs/gb_add2cart_phone.webp';
// import gbSI02PC from './imgs/si0-2_pc.png';
// import gbSI02Phone from './imgs/si0-2_phone.png';
// import gbCC02PC from './imgs/cc0-2_pc.png';
// import gbCC02Phone from './imgs/cc0-2_phone.png';
// import gbAE31PC from './imgs/ae3-1_pc.png';
// import gbAE31Phone from './imgs/ae3-1_phone.png';
// import gbSI212PC from './imgs/si2-12_pc.png';
// import gbSI212Phone from './imgs/si2-12_phone.png';
// import gbORAL212PC from './imgs/oral2-12_pc.png';
// import gbORAL212Phone from './imgs/oral2-12_phone.png';
// import orangeLine from './imgs/orange-line.png'
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

/*** GroupBuy Page ***/
import GroupBuyListTeamsComponent from "./GroupBuyListTeamsComponent";
import GroupBuyCreateGroup from "./GroupBuyCreateGroup";
import GroupBuyCheckoutCreateTeam from "./GroupBuyCheckoutCreateTeam";
import GroupBuyCheckoutAddTeam from "./GroupBuyCheckoutAddTeam";
// import GroupBuyTapPay from './GroupBuyTapPay';
import jwt_decode from "jwt-decode";

/***  antd ***/
import { Steps, Typography, Layout, Collapse, Button as AntButton } from "antd";
import "./styles/antStyle.css";
// import './styles/AdvertisingPage.css';

import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

import liff from "@line/liff"; // liff SDK
import Footer from "../Components/Footer/Footer";

/*** UTM ***/
// import UtmConfig, {Utm} from '../Components/Utm/UtmConfig';
// import UtmConfigKidPro, {Utm as UtmKidPro} from '../Components/Utm/UtmConfig';
import UtmConfig from "../Components/Utm/UtmConfig";
import UtmEvents, {
  utmEventsInit,
  utmPageView,
} from "../Components/Utm/UtmEvents";

function GroupBuy(props) {
  const { Sheet, setSheet } = props;
  const {
    authorized,
    email,
    setEmail,
    auth,
    setAuth,
    userNormalLoggedIn,
    setUserNormalLoggedIn,
    addFriendStatus,
    setAddFriendStatus,
  } = useContext(AuthContext);
  // useEffect(() => {
  //   console.log('addFriendStatus', addFriendStatus);
  // }, [addFriendStatus])
  const [liffIsInClient, setLiffIsInClient] = useState(false); // 是否為LIFF環境
  const liffIsInClientRef = useRef(liff.isInClient()); // 是否為LIFF環境Ref
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(""); // 設定login token
  const [RegxpError, setRegxpError] = useState(""); // 顯示錯誤訊息
  // const queryString = window.location.search;
  // // // // // // // // // console.log('queryString',queryString);
  // const urlParams = new URLSearchParams(queryString);
  // // // // // // // // // console.log('urlParams.get(liff.state)',urlParams.get('liff.state'));

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  let LIFF_STORE = window.localStorage.getItem("LIFF_STORE");
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  const lineAccessToken = !!window.localStorage.getItem("lat")
    ? window.localStorage.getItem("lat")
    : "";
  const addFriend = window.localStorage.getItem("addFriend") ?? ""; // 記錄是否已加入官方帳號
  const isLineBrowser = /Line/i.test(navigator.userAgent); // 是否為Line瀏覽器

  // useEffect(() => {
  //   console.log('addFriend', addFriend);
  // }, [addFriend])
  const [ShowCheckMark, setShowCheckMark] = useState(false); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState("");
  const [pidSelectedArray, setPidSelectedArray] = useState([]); // 設定選擇的課程pid
  const [userProductsArray, setUserProductsArray] = useState([]); // 設定使用者已購買的課程pid
  const [orderNum, setOrderNum] = useState(undefined); // 設定訂單編號(tappay驗證錯誤重刷)

  /***  Ant API ***/
  const { Title } = Typography; // 標題
  const { Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const { StepItem } = Steps;
  const { Panel } = Collapse;

  let checkFriendstatusTimeout = null; // 時時檢查好友狀態interval ID
  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL; // KidPro 登入導向URL
  const uaaApiURL = process.env.REACT_APP_UAA_APIURL; // UAA API URL
  const kidprocertiURL = "https://kid-pro.com/my-account/my-certificates/"; // KidPro 完課證明頁面
  const cc02URL = "https://kid-pro.com/product/cc-0-2/";
  const si02URL = "https://kid-pro.com/product/si0-2/";
  const si212URL = "https://kid-pro.com/product/si2-12/";
  const ae31URL = "https://kid-pro.com/product/ae3-1/";
  // useEffect(() => {
  //   // // // // console.log(ae31URL);
  // }, [ae31URL]);

  let loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : ""; // 取得Login Token
  let gotoKidproCerti = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + kidprocertiURL
    : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  const userNormalLoggedInStorage =
    window.localStorage.getItem("userNormalLoggedIn"); // 知道使用者是否先前是用一般登入
  let cc02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + cc02URL
    : cc02URL;
  let si02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si02URL
    : si02URL;
  let si212 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si212URL
    : si212URL;
  let ae31 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + ae31URL
    : ae31URL;

  // useEffect(() => {
  //   // // // // // // console.log('userNormalLoggedInStorage: ', userNormalLoggedInStorage);
  // }, [userNormalLoggedInStorage]);

  // 測試用
  // let veriPhoneMod = phone( '+8860911087462' );
  // // // // // // // // console.log('veriPhoneMod',veriPhoneMod);
  // let santiPhone = veriPhoneMod.phoneNumber;
  // // // // // // // // console.log('santiPhone',santiPhone);

  /*** 取得登入者資料 ***/
  // const getUserData = async() => {
  //   const decoded = jwt_decode(loginToken);
  //   // // // // // // console.log(decoded);
  //   const uid = decoded?.id || "";
  //   const email = decoded?.email || "";
  //   const atIndex = email.indexOf('@');
  //   const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
  //   // // // // // // // console.log(adminUsername);
  //   // setUserId(uid);
  //   return uid;
  // }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  // useEffect(() => {
  //   getUserData();
  // }, [loginToken]);
  /*** END 取得使用者資料 ***/

  /*** GroupBuy頁面狀態 ***/
  const [gbPage, setGbPage] = useState("LandingPage");
  // useEffect(() => {
  //   console.log("gbPage", gbPage);
  // }, [gbPage]);
  useEffect(() => {
    if (window.localStorage.getItem("loginToken")) {
      setGbPage("CreateGroupPage");
    } else {
      setGbPage("LandingPage");
    }
  }, []);
  const [teamId, setTeamId] = useState(""); // 設定團購團隊ID
  useEffect(() => {
    // // // // // // // console.log('teamId',teamId);
  }, [teamId]);

  useEffect(() => {
    // // // // // console.log('Sheet: ', Sheet, '!! loginToken', !! loginToken, 'LIFF_STOREregister', LIFF_STOREregister, 'userNormalLoggedInStorage: ', userNormalLoggedInStorage, 'email: ',  email );
    // // // // // console.log(! loginToken && LIFF_STOREregister==="true" && userNormalLoggedInStorage==="false" && email===null);
  }, [Sheet, loginToken, email, userNormalLoggedInStorage, LIFF_STOREregister]);

  /*** 第一次進入，重整頁面，以重新取得登入參數 ***/
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     const query = new URLSearchParams(window.location.search);
  //     const reload = query.get('reload');
  //     if( !! loginToken && reload !== "true" ) {
  //       if ( window.location.pathname.toLowerCase().indexOf('grantmembership') > -1 ) {
  //         // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
  //         window.location.href = "/GrantMembership?reload=true"; // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
  //       } else if ( window.location.pathname.toLowerCase().indexOf('groupbuy') > -1 && window.location.pathname.toLowerCase().indexOf('groupbuycheckout') === -1 ) {
  //         window.location.href = "/GroupBuy?reload=true"; // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
  //       } else {
  //         window.location.href = "/CheckActivity?redir=kidpro?reload=true"; // 登入成功後導向官網登入(已改成不導向直接檢查活動狀態，再導向相對應頁面)
  //       }
  //     }
  //   }, 3000);
  // },[]);

  // useEffect(()=>{
  //   // // // // // // console.log(userNormalLoggedInStorage);
  //   // // // // // // console.log(userNormalLoggedInStorage === "false");
  //   // // // // // // console.log(!!loginToken);
  //   // // // // // // console.log(( userNormalLoggedInStorage === "false" && !! loginToken ));
  //   // // // // // // console.log(userNormalLoggedInStorage === 'true' && ! loginToken);
  // },[]);

  // useEffect(()=>{
  //   // // // // // // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage);
  //   // // // // // // console.log('userNormalLoggedIn',userNormalLoggedIn);
  // },[userNormalLoggedIn]);

  /*** Login Popup相關 ***/
  const [show, setShow] = useState(false); // 設定Modal 顯示與否
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                ×
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img
                    src={
                      "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"
                    }
                  ></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  NOuaabindLine={NOuaabindLine}
                  loginTokenState={loginTokenState}
                  Loginparent={Loginparent}
                  UserData={email}
                  setNOuaabindLine={setNOuaabindLine}
                  LoginToken={LoginToken}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }; /*** END Login Popup相關 ***/

  /*** UTM Events ***/
  const [utm, setUtm] = useState({});
  const [utmKidPro, setUtmKidPro] = useState({});
  const [utmPageViewEventId, setUtmPageViewEventId] = useState("");
  const [utmAddToCartEventId, setUtmAddToCartventId] = useState("");
  const [utmCheckoutEventId, setUtmCheckoutEventId] = useState("");
  const [utmPurchaseEventId, setUtmPurchaseEventId] = useState("");
  const configUTM = async () => {
    // // // // console.log('configUTM')
    try {
      // const pixelId = process.env.REACT_APP_FB_PIXEL_ID ?? "";
      const gaId = process.env.REACT_APP_GA4_ID ?? "";
      // const pixelIdKidpro = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO ?? "";
      const pixelIdKidpro2 = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO2 ?? "";
      const gaIdKidpro = process.env.REACT_APP_GA4_ID_KIDPRO ?? "";
      // const Utm = await UtmConfig(pixelId, gaId);
      // // // // console.log('Utm:', Utm);

      // const UtmKidPro = await UtmConfig(pixelIdKidpro, gaIdKidpro);
      // // // // console.log('UtmKidPro:', UtmKidPro);

      const UtmKidPro2 = await UtmConfig(pixelIdKidpro2, gaIdKidpro);

      // setUtm(Utm);
      // setUtmKidPro(UtmKidPro);
      setUtmKidPro(UtmKidPro2);

      // return {UtmKidPro};
      return {
        // Utm, UtmKidPro,
        UtmKidPro2,
      };
      // 在这里可以继续处理其他逻辑
    } catch (error) {
      throw new Error(error);
    }
    //   // // // // console.log('Utm', Utm);
    //   // // // // console.log('UtmKidPro', UtmKidPro);
  };
  useEffect(() => {
    configUTM();
  }, []);
  // useEffect(() => {
  //   // // // // console.log('Utm', Utm);
  //   // // // // console.log('UtmKidPro', UtmKidPro);
  // }, [Utm, UtmKidPro]);
  const [contentName, setContentName] = useState("三人成團購課－活動頁面");
  const [contentCategory, setContentCategory] = useState(
    "kidpro_marketing_groupbuy"
  );
  const [itemIds, setItemIds] = useState([]); // product id array
  const [items, setItems] = useState([]); // product object array
  const [currency, setCurrency] = useState("TWD");
  const [value, setValue] = useState(NaN);
  const [utmOrderNumber, setUtmOrderNumber] = useState(NaN);
  const [shareMethod, setShareMethod] = useState("");
  UtmEvents(
    contentName,
    contentCategory,
    itemIds,
    items,
    currency,
    value,
    utmOrderNumber,
    shareMethod
  ); // UTM事件全域參數設定
  const utmPageViewAsync = async () => {
    //  utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    //  utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    const {
      // Utm, UtmKidPro,
      UtmKidPro2,
    } = await configUTM();
    // // // // console.log({Utm, UtmKidPro});
    // await utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    let eventID = "";
    try {
      // // // console.log('eventID', eventID)
      eventID = await conversionApiViewPage();
      // // // console.log('eventID', eventID)
    } catch (error) {
      // // console.log(error)
      throw new Error("Request failed: utmPageViewAsync()");
    }
    // // // console.log('eventID', eventID)
    // await utmPageView(Utm, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    // await utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    await utmPageView(UtmKidPro2, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
  };
  useEffect(() => {
    utmPageViewAsync();
  }, []);
  /*** END UTM Events ***/

  // /***** UTM相關(舊版) *****/
  // /***  取得UTM ***/
  // const getUtmParams = async(event) => {
  //   const urlParams = window.location.search;
  //   const urlParamsObj = new URLSearchParams(urlParams);
  //   if( urlParamsObj.has("utm_id") ) {
  //     const queryString = urlParamsObj.toString();
  //     window.localStorage.setItem("utm", queryString);
  //     const queryEventString = queryString + "&utm_event=" + event;
  //     return queryEventString;
  //   }
  //   return "";
  // }
  // /*** 第一次進入頁面傳UTM ***/
  // const sendUtmParams = async() => {
  //   const queryEventString = await getUtmParams("view");
  //   if(queryEventString) {
  //     await fetch('https://' + apiURL + '/api/marketing/v1/utm-recorder?' + queryEventString, {
  //       method: 'POST',
  //     })
  //     .then((r) => {
  //       if( ! r.ok ) {
  //         // // // // // // // console.log("utm sending error");
  //       }
  //     })
  //     .catch((error) => {
  //       // // // // // // // console.log("utm sending error", error);
  //     });
  //   }
  // }
  // useEffect(()=>{sendUtmParams()},[]);

  // /*** FB Pixel 相關 ***/
  // const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // // Pixel組態設定
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // };
  // ReactPixel.init(FbPixelID, {}, options);
  // const pixelPageView = () => {
  //   ReactPixel.trackCustom('瀏覽【三人成團購課－活動頁面】', {content_category: 'kidpro_marketing_page', content_ids: 'view_grant_membership', content_name: '三人成團購課－活動頁面' });
  // }
  // // const pixelSubmitForm = () => {
  // //   ReactPixel.trackCustom('點選【確認更新】送出寶寶表單', {content_category: 'kidpro_marketing_submit', content_ids: 'submit_babyform', content_name: '完成LINE綁定送銀卡會員－活動頁－確認更新'});
  // // }
  // /*** END FB Pixel 相關 ***/

  // const [cookies, setCookie] = useCookies(['utm']); // useCookies hook
  // // // // // // // // // console.log('cookies.cid', cookies.cid);

  // /*** GA4相關 ***/
  //  // ReactGA.send({ hitType: "pageview", page: '/' }); // GA pageview設定
  // /*  page_view event parameters
  //  * page_location	string	No	https://example.com	The URL of the page.
  //  * client_id	string	No	ABC_123	The client ID.
  //  * language	string	No	en_us	The language for the client. See Language tags and codes.
  //  * page_encoding	string	No	UTF-8	The encoding of the page.
  //  * page_title	string	No	Home	The title of the page.
  //  * user_agent	string	No		The client's user agent.
  //  */
  // /* 瀏覽頁面時，送出事件給GA4 */
  // const sendGa4 = () => {
  //   ReactGA.event( 'page_view', {
  //     page_location: window.location.pathname,
  //     client_id: cookies._ga,
  //     language: 'zh_tw',
  //     page_encoding: 'UTF-8',
  //     page_title: 'Marketing - 三人成團購課－活動頁面',
  //   });
  //   ReactGA.send({ hitType: "event" });
  //   // // // // // // // // console.log('ReactGA',ReactGA);
  // }
  // /* 瀏覽頁面的事件只在渲染時執行一次 */
  // useEffect(() => {
  //   pixelPageView();
  //   sendGa4();
  // }, []);
  // /*** END GA4相關 ***/
  // /***** END UTM相關(舊版) *****/

  /*** Conversion API ***/
  const conversionApiViewPage = async () => {
    let BearerToken = undefined;
    if (loginToken) {
      BearerToken = "Bearer " + loginToken;
    }
    try {
      const response = await fetch(
        "https://" +
          microservApiURL +
          "/view-content?sourceUrl=" +
          encodeURI(window.location.href),
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed: conversionApiViewPage()");
      }
      const result = await response.json();
      // // // console.log(result)
      if (result.success) {
        const eventID = result?.trackerData?.eventId;
        return eventID;
      } else {
        throw new Error("Request failed: conversionApiViewPage()");
        // return '';
      }
    } catch (error) {
      // console.log('ConversionApiViewPage Error');
      // throw new Error('ConversionApiViewPage Error: ', error);
      // return '';
    }
  };
  // const conversionApiViewPage = async() => {
  //   let BearerToken = undefined;
  //   if( loginToken ) {
  //     BearerToken = 'Bearer ' + loginToken;
  //   }
  //   await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
  //     method: 'GET',
  //     credentials: "include",
  //     headers: {
  //       'Authorization': BearerToken,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // // console.log('result', result)
  //     if(result.success) {
  //       // // // // console.log('ConversionApiViewPage Success.');
  //       const eventID = result?.trackerData?.eventId;
  //       // // console.log('eventID', eventID)
  //       return eventID
  //     } else {
  //       // // // // console.log('ConversionApiViewPage Failure.', result);
  //       return ''
  //     }
  //   })
  //   .catch((error) => {
  //     // // // // console.log('ConversionApiViewPage Error');
  //     return ''
  //   });
  // }
  // useEffect(()=>{
  //   conversionApiViewPage();
  // },[]);
  /*** END Conversion API ***/

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("smsVeriStatus");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("url");
    // }, false);
  };

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // // // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    // localStorage.clear(); // 20230802 註解此段，避免自動登出
    setNOuaabindLine(false);
    setEmail(null);
    // setTimeout(()=>{
    //   setSheet('LoginIndex');
    // }, 3000);
  };

  // 設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  const copyText = () => {
    let copy =
      "#KidPro醫學級兒童發展教育團隊_恬兒職能治療師\n#輸入底下優惠碼享購課94折\n【#KidPro科學育兒在你身邊】";
    // let copy = '@恬兒職能治療師-醫學領域的兒童發展專家\n#KidPro優惠碼【科學育兒】購買線上課程享94折';
    navigator.clipboard
      .writeText(copy)
      .then(() => {
        //複製完成顯示checkMark
        setShowCheckMark(true);
        //複製完成三秒後checkMark消失
        setTimeout(() => {
          setShowCheckMark(false);
        }, 3000);
      })
      .catch(() => {
        setShowCheckMark(false);
      });
  };

  const lineid = process.env.REACT_APP_LINE_APP_ID;
  // useEffect(() => {
  //   console.log('lineid',lineid);
  // }, [lineid])

  let line_liff_loginData = {};

  /* 使用者若是點LINE註冊(有綁定LINE帳號會自動登入)，檢驗使用者是否已綁定過LINE。 (LINE登入走LoginIndex->LoginEncode->CheckActivity->導向KidPro官網登入－> ( 1.有JWT: 回CheckActivity檢驗活動狀態－>導向首頁/submitpage/countingpage ) ( 2.無JWT： 導向首頁繼續下方判斷 ) */
  /*
   * liffLogin自動登入只有在Liff Browser才會執行
   * v1. 開始進入畫面，不彈出視窗(loginToken == null、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage==null、email==null)
   * v4. 未綁定，一開始就點LINE註冊(loginToken == null、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-1. 未綁定，一開始就點LINE註冊，LINE Login Token綁定舊帳號(用Wordpress登入)，關閉視窗(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-1-1. 登入成功，關閉視窗，同4-3.
   *  v4-2. 未綁定，一開始就點LINE註冊，LINE Login Token註冊Wordpress新帳號(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="null"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-1. 註冊成功，要求進入登入畫面(此處setSheet由RegisterEmail控制)(loginToken == null、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-2. 登入成功，關閉視窗.(loginToken == "字串"、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-3. 已綁定，一開始就點LINE註冊，LINE Login Token直接登入(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   * v6. 未綁定直接點選LINE登入，輸入wordpress帳號後登入，關閉視窗，同4-1. 4-2.
   * v7. 已綁定直接點選LINE登入，直接登入，關閉視窗，同4-3. 4-1-1.
   * 8. 其他狀況，回login index，預設關閉視窗
   */

  const liffLogin = useCallback(
    async (count) => {
      // console.log('linelogin');
      /* 在LINE web view自動登入 */
      const init = await liff
        .init({
          liffId: lineid, // Use own liffId
          // withLoginOnExternalBrowser: true // Enable automatic login process at External Browser
        })
        .then(() => {
          if (
            isLineBrowser &&
            window.sessionStorage.getItem("autoLogin") !== "false" &&
            (!window.localStorage.getItem("loginToken") ||
              window.localStorage.getItem("loginToken") === "")
          ) {
            const isLoggedIn = liff.isLoggedIn();
            const liffLoginRedir =
              "https://" + backendURL + "/LoginEncode?page=GroupBuy";
            // const liffLoginRedir = "https://liff.line.me/" +  lineid + "?page=SubmitPage";
            if (!isLoggedIn) {
              liff.login({ redirectUri: liffLoginRedir });
              // liff.login();
            }
            if (isLoggedIn) {
              const accessToken = liff.getAccessToken();
              line_liff_loginData = {
                accessToken: accessToken,
              };
              fetch("https://uaa.kid-pro.com/api/uaa/line_liff_login", {
                method: "POST",
                body: JSON.stringify(line_liff_loginData),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((r) => r.json())
                .then((result) => {
                  console.log("result", result);
                  if (result.status === 200) {
                    // 1. LINE登入/註冊成功
                    // console.log("1. LINE登入/註冊成功");
                    const loginToken = result?.data?.loginToken || "";
                    window.localStorage.setItem(
                      "lineRegisterLoginToken",
                      loginToken
                    ); // 此loginToken可能為未綁定的loginToken，只做記錄，不登入
                    const BearerToken = "Bearer " + loginToken;
                    fetch("https://" + uaaApiURL + "/api/uaa/user_pofile", {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: BearerToken,
                      },
                    })
                      .then((r) => r.json())
                      .then((result) => {
                        const wpEmail = result?.data?.email || "";
                        if (wpEmail) {
                          // 2. Wordpress帳號已存在(已綁定LINE)，直接登入
                          // console.log(
                          //   "2. Wordpress帳號已存在(已綁定LINE)，直接登入"
                          // );
                          window.localStorage.setItem("loginToken", loginToken);
                          setGbPage("CreateGroupPage");
                          // window.reload();
                          setTimeout(() => {
                            handleClose();
                          }, 2000);
                        } else {
                          // 3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)
                          // console.log(
                          //   "3. Wordpress帳號不存在(未綁定LINE)，彈出綁定舊帳號/創立新帳號視窗，詢問是否綁定舊帳號或創立新帳號(可能不會有此狀況?)"
                          // );
                          setSheet("NOuaabindLine");
                          window.localStorage.setItem(
                            "LIFF_STOREregister",
                            "true"
                          );
                          setTimeout(() => {
                            handleShow();
                          }, 2000);
                        }
                      })
                      .catch((error) => {
                        console.log("error", error);
                        // alert('伺服器錯誤，請稍候再試！');
                      });
                  } else {
                    // 4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定
                    // console.log(
                    //   "4. Wordpress帳號不存在(未綁定LINE)，不讓使用者直接登入，而是要求先綁定舊帳號或創新帳號綁定"
                    // );
                    window.localStorage.setItem("LIFF_STOREregister", "true");
                    setTimeout(() => {
                      handleShow();
                    }, 3000);
                  }
                });
            } else {
              if (!count && !Number.isInteger(count)) {
                count = 0;
              }
              if (count < 3) {
                // 最多重複登入三次
                liffLogin(++count);
              }
            }
          }
        })
        .catch((err) => {
          console.Consolelog(err);
        });

      // const isLoggedIn = liff.isLoggedIn();
      // console.log('line',isLoggedIn);
      // if( isLoggedIn === true ) {
      //   // // // // // // // console.log('isLoggedIn === true',isLoggedIn === true);
      //   let redirectUrl = window.location.href
      //   const accessToken = liff.getAccessToken();
      //   line_liff_loginData = {
      //     accessToken: accessToken,
      //   }
      //   fetch('https://uaa.kid-pro.com/api/uaa/line_liff_login', {
      //     method: 'POST',
      //     body: JSON.stringify(line_liff_loginData),
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //   })
      //   .then((r) => r.json())
      //   .then((result) => {
      //     // // // // // // // console.log('result',result);

      //     // 20230802之前的版本(無誤)
      //     if( result.status === 200 && ! LIFF_STOREregister ) { // 1. LINE登入/其他登入狀況，使用者已綁定，導向CheckActivity進行登入並檢查活動
      //       // 從line登入頁面進來
      //       // navbar show 登出 有token  NOuaabindLine false(有綁定line and uaa)
      //       window.localStorage.setItem( 'loginToken', result.data.loginToken );
      //       setNOuaabindLine(false);
      //       setAuth({...auth,authorized:true});
      //       // if( !! loginToken ) {
      //         // window.location.assign( "/CheckActivity?redir=kidpro" );
      //       // }
      //       // // // // // // // // console.log('01');
      //     } else if( result.status === 200 && LIFF_STOREregister ) { // 2. LINE註冊，使用者已綁定
      //       // onsole.log('02');
      //       // 從註冊頁面line登入進來，有帳號uaa+line，進入帳號已存在popup
      //       window.localStorage.setItem( 'lineRegisterLoginToken', result.data.loginToken ); // 只記錄login token，而不做登入；待使用者點選登入才登入
      //       const BearerToken = 'Bearer ' + result.data.loginToken;
      //       fetch('https://' + uaaApiURL + '/api/uaa/user_pofile', {
      //         method: 'GET',
      //         headers: {
      //           'Content-Type': 'application/json',
      //           'Authorization': BearerToken
      //         }
      //       })
      //       .then((r) => r.json() )
      //       .then((result) => {
      //         if( ! window.localStorage.getItem('loginToken') ) {
      //           // setTimeout(()=>{
      //           //   setSheet('accountExist');
      //           // }, 3000);

      //           // setEmail(result.data.email); // 設定email，記錄wordpress帳號已存在
      //           // handleShow(); // 未登入則顯示帳號已存在(accountExist)，問使用者是否登入

      //         } else {
      //           // handleClose(); // 已登入則不彈出登入視窗
      //         }
      //       })
      //       .catch((error) => {
      //         // // // // // // // // // console.log('error', error);
      //         alert('伺服器錯誤，請稍候再試！');
      //       });
      //     }
      //     // END 20230802之前的版本

      //     // // 20230802之後的版本，改成強迫使用者做LINE登入或綁定
      //     // if( result.status === 200  ) { // 1. LINE登入/LINE註冊/其他登入狀況：LINE登入成功(不直接登入只記錄)，表示使用者已綁定，強迫使用LINE Login API取得的login token查詢使用者資料。如果可執行，表示後台已有此使用者Wordpress帳號，檢查使用者是否已登入；不可執行，則表示沒有此人Wordpress帳號
      //     //   // // // // // // console.log(NOuaabindLine);
      //     //   // 從註冊頁面line登入進來，有帳號uaa及line，帳號已存在
      //     //   window.localStorage.setItem( 'lineRegisterLoginToken', result.data.loginToken ); // 只記錄login token，而不做登入；待使用者點選登入才登入
      //     //   const BearerToken = 'Bearer ' + result.data.loginToken;
      //     //   fetch('https://uaa.kid-pro.com/api/uaa/user_pofile', {
      //     //     method: 'GET',
      //     //     headers: {
      //     //       'Content-Type': 'application/json',
      //     //       'Authorization': BearerToken
      //     //     }
      //     //   })
      //     //   .then((r) => r.json() )
      //     //   .then((result) => {
      //     //     if( ! window.localStorage.getItem('loginToken') ) {
      //     //       // setSheet('accountExist');
      //     //       setEmail(result.data.email); // 設定email，記錄wordpress帳號已存在，以進入AccountExist頁面，此時若使用者輸入點選登入，才將Login Token記錄在localStorage，並導向CheckActivity檢查活動狀態
      //     //       handleShow(); // 未登入則顯示帳號已存在，問使用者是否登入
      //     //     } else {
      //     //       handleClose(); // 已登入則不彈出登入視窗，表示此人已有用LINE綁定Wordpress帳號並已登入，不需要做任何操作
      //     //     }
      //     //   })
      //     //   .catch((error) => {
      //     //     // // // // // // // // // console.log('error', error);
      //     //     alert('伺服器錯誤，請稍候再試！');
      //     //   });
      //     // } // END 20230802之後的版本，改成強迫使用者做LINE登入或綁定

      //     else { // 3. 回傳狀態碼非200，表示用LINE Login Token登入後可找到使用者Wordpress資料；要將LINE Login Token綁定舊Wordpress帳號(登入後自動綁定LINE)，或用LINE Login Token註冊新Wordpress帳號(註冊後自動綁定LINE)
      //       if( ( result.statusCode === 400 || result.statusCode === 403 ) && LIFF_STOREregister ) { // 3-1. localStorage有LIFF_STOREregister，表示由LINE註冊進入，使用者未綁定且無帳號
      //         //從註冊頁面line登入進來，無帳號，進入NoLineDataLogin(Wordpress帳號登入(同時綁定LINE)或註冊新Wordpress會員(同時綁定LINE)畫面)
      //         // handleShow(); // show 登入畫面
      //         // setSheet('registerPhone');
      //         setTimeout(()=>{
      //           setSheet('NOuaabindLine');
      //         }, 3000);
      //       } else if( ( result.statusCode === 400 || result.statusCode === 403 ) && ! LIFF_STOREregister ) { // 3-2. localStorage沒有LIFF_STOREregister，表示由LINE登入進入，使用者未綁定
      //         //從line登入頁面進來 無綁定 進入NoLineDataLogin(Wordpress帳號登入(同時綁定LINE)或註冊新Wordpress會員(同時綁定LINE)畫面)
      //         // handleShow(); // show 登入畫面
      //         //setNOuaabindLine(false);
      //         setTimeout(()=>{
      //           setSheet('NOuaabindLine');
      //         }, 3000);
      //       } else {
      //         alert('伺服器錯誤，請稍候再試！');
      //         // console.log('LINE登入失敗！')
      //         //show 登入
      //       }
      //     }
      //   });
      // }
    },
    [isLineBrowser, lineid]
  );

  /* 彈出註冊成功 */
  const popupRegisterSuccess = () => {
    let queryString = window.location.search; // 由驗證Email之連結取得url query字串
    let urlParams = new URLSearchParams(queryString); // 分析url query字串
    let regStatus = urlParams.get("register"); // 取得url query字串中的註冊狀態值
    // // // // // // // // // console.log('redir', redir);
    if (regStatus === "true") {
      // // // // // // // // // console.log('regStatus', regStatus );
      // setSheet('registerSucess');
      handleShow();
    }
  };

  const collapseToggle = (e, idx) => {
    e?.preventDefault();
    const panelI = document?.getElementsByClassName(`coursePanel-${idx}`)[0];
    if (!!panelI && panelI.style.display === "block") {
      panelI.style.display = "none";
    } else if (!!panelI) {
      panelI.style.display = "block";
    }
  };

  /* 登入兩秒後，檢查好友狀態 */
  // 設定有沒有 Line 加入官方帳號
  // const [ifLineFriendStatus, setIfLineFriendStatus] = useState(false);
  // useEffect(() => {
  // // // console.log('ifLineFriendStatus: ', ifLineFriendStatus);
  // }, [ifLineFriendStatus])
  // const checkFriendstatus = (event) => {
  //   // setIsLoadingLineFriend(true);
  //   // console.log('checkFriendstatus')
  //   if( !! event ) {
  //     event.preventDefault();
  //   }
  //   liff.init({
  //     liffId: lineid, // Use own liffId
  //   })
  //   .then(() => {
  //     // 檢查LINE是否登入，若有則檢查好友狀態
  //     // let isLoggedIn = liff.isLoggedIn();
  //     // // // console.log('Line isLoggedIn: ', isLoggedIn);
  //     // if( isLoggedIn ) {
  //     checkFriendstatusTimeout = setTimeout( function() {
  //       liff.getFriendship().then( data => {
  //         // // // console.log('getFriendship', data.friendFlag);
  //         if( data.friendFlag === true ) {
  //           // // // console.log('data.friendFlag: ', data.friendFlag)
  //           // setIsLoadingLine(false);
  //           // setIsLoadingLineFriend(false);
  //           setAddFriendStatus(true);
  //           window.localStorage.setItem('addFriend', 'true');
  //           // setFriendRegxpError('已加入KidPro好友！');
  //           // setIntervalLineMsg(false);
  //         } else {
  //           // // console.log('data.friendFlag: ', data.friendFlag)
  //           // setIsLoadingLine(false);
  //           // setIsLoadingLineFriend(false);
  //           // liff.getFriendship();
  //           // setFriendRegxpError(<span>您尚未將KidPro加入好友（請先<a href='https://line.me/R/ti/p/%40lzb1751t'>【加入KidPro好友】</a>，或將KidPro官方帳號解除封鎖）！</span>);
  //           // setIntervalLineMsg(false);
  //           // setIfLineFriendStatus(false);
  //           setAddFriendStatus(false);
  //           window.localStorage.setItem('addFriend', 'false');
  //         }
  //       });
  //     }, 2000 );
  //     // }
  //     //  else {
  //     //   setIsLoadingLine(false);
  //     //   // setIsLoadingLineFriend(false);
  //     //   if( intervalLineMsg ) {
  //     //     setFriendRegxpError('請先登入LINE再點選加入好友！');
  //     //   }
  //     //   setIntervalLineMsg(false);
  //     //   liff.logout();
  //     // }
  //   })
  //   .catch((err) => {
  //     // // // console.log('Error: ', err);
  //     // setIsLoadingLine(false);
  //     // setIsLoadingLineFriend(false);
  //     // Todo: retry init.
  //     // // // // console.log('err',err);
  //     // if( intervalLineMsg ) {
  //     //   setFriendRegxpError('無法取得LINE平台回應，請稍候再試！');
  //     // }
  //     // setIntervalLineMsg(false);
  //     // liff.logout();
  //     // setIfLineFriendStatus(false);
  //     setAddFriendStatus(false);
  //     window.localStorage.setItem('addFriend', 'false');
  //   });
  // }
  // useEffect(() => {
  //   checkFriendstatus();
  // }, [])

  /*** 20230802之後新增的版本 只要localStorage中的userNormalLoggedInStorage為true，彈出LINE登入視窗 ***/
  /** 目標：
   * v1. 開始進入畫面，不彈出視窗(loginToken == null、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage==null、email==null)
   * v2. 未綁定一般登入後，顯示LINE註冊/登入視窗(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * 3. 未綁定一般登入，LINE註冊/登入點擊後(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="false"、email==null)
   *  v3-1. 未綁定一般登入，LINE註冊/登入點擊後，直接綁定並登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   *  // v3-2. 已綁定一般登入，LINE註冊/登入點擊後，LINE Login Token直接登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email=="字串")
   * v4. 未綁定，一開始就點LINE註冊(loginToken == null、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-1. 未綁定，一開始就點LINE註冊，LINE Login Token綁定舊帳號(用Wordpress登入)，關閉視窗(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-1-1. 登入成功，關閉視窗，同4-3.
   *  v4-2. 未綁定，一開始就點LINE註冊，LINE Login Token註冊Wordpress新帳號(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="null"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-1. 註冊成功，要求進入登入畫面(此處setSheet由RegisterEmail控制)(loginToken == null、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-2. 登入成功，關閉視窗.(loginToken == "字串"、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-3. 已綁定，一開始就點LINE註冊，LINE Login Token直接登入(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   * ov5. 已綁定，一般登入後，直接儲存WP Login Token登入，關閉視窗(loginToken == "字串"、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * v6. 未綁定直接點選LINE登入，輸入wordpress帳號後登入，關閉視窗，同4-1. 4-2.
   * v7. 已綁定直接點選LINE登入，直接登入，關閉視窗，同4-3. 4-1-1.
   * 8. 其他狀況，回login index，預設關閉視窗
   */
  /**
   * 20230904 目標：插入加入LINE官方帳號流程
   * 做法：登入成功後(3-1, 4-1-1, 4-2-2, 4-3, 5)檢查是否已綁定LINE官方帳號
   * v5必須改：新增必須已綁定並加入官方帳號才不彈出LINE登入
   */
  /*** userNormalLoggedInStorage false => LINE登入成功(不彈出) ***/
  /*** userNormalLoggedInStorage 不存在 => 一般登入(不彈出) ***/
  /*** userNormalLoggedInStorage true => LINE登入(彈出) ***/
  const popupLineLogin = useCallback(() => {
    // console.log('loginToken', loginToken)
    // console.log('LIFF_STOREregister', LIFF_STOREregister)
    // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
    // console.log('email', email)
    // console.log('addFriend', addFriend)
    // v1. 4.
    if (
      !loginToken &&
      !LIFF_STOREregister &&
      !userNormalLoggedInStorage &&
      email === null
    ) {
      // // // // // console.log("1. 4.");
      // console.log('loginToken', loginToken)
      // console.log('LIFF_STOREregister', LIFF_STOREregister)
      // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
      // console.log('email', email)
      // console.log('addFriend', addFriend)
      // alert('1. 4.將關閉視窗')
      handleClose(); // 關閉視窗，主動點選還是會顯示
      // setSheet('LoginIndex'); 1.
      // setSheet('register'); 4.
      // v2.
    } else if (
      !loginToken &&
      !LIFF_STOREregister &&
      userNormalLoggedInStorage === "true" &&
      email === null
    ) {
      // // // // // console.log("2.");
      // alert("2.彈出視窗")
      handleShow();
      // setSheet('register');
      // v3-1. 4.3
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      userNormalLoggedInStorage === "false" &&
      email === null
      // && addFriend === "true"
    ) {
      // // // // // console.log("3-1.");
      // console.log('loginToken', loginToken)
      // console.log('LIFF_STOREregister', LIFF_STOREregister)
      // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
      // console.log('email', email)
      // console.log('addFriend', addFriend)
      // alert('3-1.將關閉視窗')
      handleClose();
      // setSheet('register');
      // // v3-1. 4.3 登入成功，未加入官方帳號
      // } else if( !! loginToken && LIFF_STOREregister === "true" && userNormalLoggedInStorage==="false" && email===null && ! addFriend ) {
      //   // // // // // console.log("3-1.");
      //   handleShow();
      //   // setSheet('register');
      // v3-2.
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      userNormalLoggedInStorage === "true" &&
      email === null
    ) {
      // // // console.log("3-2. 5.");
      // console.log('loginToken', loginToken)
      // console.log('LIFF_STOREregister', LIFF_STOREregister)
      // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
      // console.log('email', email)
      // console.log('addFriend', addFriend)
      // alert('3-2. 5.將關閉視窗')
      handleClose();
      // setSheet('LoginIndex');
      // 4-1. 4-2. 6
    } else if (
      !loginToken &&
      LIFF_STOREregister === "true" &&
      !userNormalLoggedInStorage &&
      email === null
    ) {
      // // // // // console.log("4-1. 4-2. 6");
      // alert("4-1. 4-2. 6. 彈出視窗")
      handleShow();
      // setTimeout(()=>{
      //   setSheet('NOuaabindLine');
      // }, 3000);
      // setSheet('NOuaabindLine');
      // 4-1-1. 4-2-2.
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      !userNormalLoggedInStorage &&
      email === null
      // && addFriend === "true"
    ) {
      console.log("4-3. 4-1-1.");
      // console.log('loginToken', loginToken)
      // console.log('LIFF_STOREregister', LIFF_STOREregister)
      // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
      // console.log('email', email)
      // console.log('addFriend', addFriend)
      // alert('4-1-1. 4-2-2.將關閉視窗')
      handleClose();
      // setSheet('register');
      // 4-1-1. 4-2-2. 登入成功，未加入官方帳號
      // } else if( !! loginToken && LIFF_STOREregister==="true" && ! userNormalLoggedInStorage && email===null && ! addFriend  ) {
      //   // // // // // console.log("4-3. 4-1-1.");
      //   handleShow();
      //   // setSheet('register');

      // v5.20230906新增條件，已加入官方帳號，仍先彈出綁定視窗做是否加入好友判斷
      // } else if( !! loginToken && addFriend === "true" && ! LIFF_STOREregister ) {
      //   // // // // // console.log("else");
      //   // window.localStorage.clear();
      //   // setEmail(null);
      //   alert('已加入官方帳號，仍先彈出綁定視窗做是否加入好友判斷')
      //   handleShow();
      // // 5. (true null true null) 未加入官方帳號，彈出綁定視窗或加入好友視窗
      // } else if( !! loginToken && ( ! addFriend || addFriend === "false" ) ) {
      //   // // // // // console.log("else");
      //   // window.localStorage.clear();
      //   // setEmail(null);
      //   // alert('未加入官方帳號，彈出綁定視窗或加入好友視窗')
      //   handleShow();
    } else {
      // // // // // console.log("else");
      // window.localStorage.clear();
      // setEmail(null);
      // console.log('loginToken', loginToken)
      // console.log('LIFF_STOREregister', LIFF_STOREregister)
      // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
      // console.log('email', email)
      // console.log('addFriend', addFriend)
      // alert('Other,將關閉視窗')
      handleClose();
    }
    // if( userNormalLoggedInStorage !== "false" ) { // 一般登入前、一般登入後LINE登入前
    //   handleShow();
    // } else if( userNormalLoggedInStorage !== 'true' || loginToken ) { // 一般登入前、一般登入後LINE登入前
    //   handleShow();
    // } else {
    //   handleClose();
    // }
  }, [loginToken, LIFF_STOREregister, userNormalLoggedInStorage, email]);
  // useEffect(() => { // 有可能開始載入時未取得addFriend，需重複載取
  //   popupLineLogin();
  // }, [addFriend]);

  useEffect(() => {
    liffLogin();
    previousPageClearStorage();
    popupLineLogin(); // 20230802之後新增 彈出LINE註冊/登入視窗
    // collapseToggle();
    // checkUserLineBind();
    // popupRegisterSuccess();
  }, [liffLogin, popupLineLogin]);

  useEffect(() => {
    if (liff.isInClient()) {
      setLiffIsInClient(true);
    }
  }, []);

  return (
    <>
      {gbPage === "LandingPage" ? (
        <Container fluid className={style.container_fluid}>
          <Row className={style.gbContainerRow}>
            <Col className={style.container_customMargin}>
              <Col className={style.bannerColPhone}>
                <Layout className={style.layoutPhone}>
                  {/* 20240711 */}
                  <img
                    style={{ width: "100%" }}
                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/e401577c-5c24-49a7-cbb5-4e55a5db2100/keepOrigin"
                  ></img>
                </Layout>
              </Col>
              <Col className={style.bannerColPC}>
                <div className={style.bannerSec}>
                  <div className={style.bannerTitleImgDiv}>
                    <Layout className={style.layoutComp}>
                      <img
                        style={{ width: "100%", paddingBottom: 20 }}
                        src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/fbf8af38-bb5a-4397-df47-f39c2e6eaf00/keepOrigin"
                      ></img>
                    </Layout>
                  </div>
                </div>
              </Col>
              <Col className={style.gbMain}>
                <Col className={style.memberTableCol}>
                  <div className={style.memberDescriptDiv}>
                    <Title level={3} className={style.titleH3_6}>
                      湊滿3人合購線上課程
                    </Title>
                    <Title level={5} className={style.titleH5}>
                      依團購課程數量 享有不同折扣
                    </Title>
                    <Title level={5} className={style.titleH5}>
                      1堂4折，2堂3.5折，3堂3.3折，4堂3.2折，5堂3折
                    </Title>
                  </div>
                </Col>
                <Row className={style.gbRowCourse}>
                  <Col className={style.gbColCourse02Title}>
                    <Title level={3} className={style.titleH3_3}>
                      新生兒必買
                    </Title>
                  </Col>
                  <Col className={style.gbColCourse02Content}>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={`${style.gbImgCoursePhone}`}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/c201c113-e121-4b28-15fa-1b08f9a8b500/keepOrigin"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/cc-0-2/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          onClick={(e) => {
                            collapseToggle(e, 0);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            嬰幼兒 父母必修課
                          </Title>
                          <Title level={4} className={style.titleH4_2}>
                            原價：8800元
                          </Title>
                          <Title level={4} className={style.titleH3_2}>
                            團購價：最低3折 2640元
                          </Title>
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-0`}
                      >
                        　　本課程整合父母在孩子出生到三歲前應具備的相關知識，以及教養技巧，建立正確的科學教養知識，未來可以分辨市面上各種廣告或教養方式的能力。這堂課，由醫學領域中各類育兒專家共同企劃包含心理、語言、職能等，內容涵蓋父母心理、語言萌發技巧、動作發展處進、正確進食喝水觀念、副食品、幼兒情緒、嬰兒按摩等。影片實際示範教學，實際好操作，深入淺出。未來將陸續將課程內容將進行升級，將陸續加入兒科醫師、泌乳相關、護理等知識，是一堂活的線上課程，課程中有任何疑問都可以透過LINE與老師溝通詢問。是新生兒父母必備的育兒秘笈，已服務超過6400名學員，是全台灣最暢銷的育兒線上課程。
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/cc-0-2");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/28e0831b-6f6c-41ba-c385-b30c2719ec00/keepOrigin"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/si0-2/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          onClick={(e) => {
                            collapseToggle(e, 1);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            嬰幼兒 感覺統合啟蒙課程
                          </Title>
                          <Title level={4} className={style.titleH4_2}>
                            原價：5800元
                          </Title>
                          <Title level={4} className={style.titleH3_2}>
                            團購價：最低3折 1740元
                          </Title>
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-1`}
                      >
                        　　本課程適用三歲前嬰幼兒家長或保姆，感覺統合理論由美國南加大A.
                        Jean
                        Ayres博士提出，結合神經生理、神經心理學的理論，透過七感的輸入調整幫助孩子大腦神經對於各類刺激做出合適的反應，進而促進孩子大腦健全發展，職能治療師常透過感統來改善孩子常跌倒、力道控制不得宜、衝動、情緒起伏大、專注力差、學習障礙、行為問題、語言發展遲緩、坐立不安、感到沮喪、具有攻擊傾向、與別人談話時會靠得過近等問題。課程包含70種以上遊戲，搭配不同月齡有不同玩法，透過遊戲幫助孩子發展，促進親子互動，寓教於樂。遊戲設計取材自生活中、教室裡常見的資源與用品，非常便利！已服務超過4100名學員，歡迎加入！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/si0-2");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className={style.gbRowCourse}>
                  <Col className={style.gbColCourse212Title}>
                    <Title level={3} className={style.titleH3_4}>
                      2歲以上必修
                    </Title>
                  </Col>
                  <Col className={style.gbColCourse212Content}>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/12da8e21-c7b6-4c4d-7b74-704fd7ed6800/keepOrigin"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/si-2-12/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          onClick={(e) => {
                            collapseToggle(e, 2);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童 感覺統合訓練課程
                          </Title>
                          <Title level={4} className={style.titleH4_2}>
                            原價：5800元
                          </Title>
                          <Title level={4} className={style.titleH3_2}>
                            團購價：最低3折 1740元
                          </Title>
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-2`}
                      >
                        　　本課程適用2-12歲兒童家長與幼教人員，內含61個遊戲，160種玩法，遊戲道具多數取材自日常生活常見的資源與道具，是促進親子感情、幫助孩子感統發展的最佳課程。感覺統合理論由美國南加大A.
                        Jean
                        Ayres博士提出，結合神經生理、神經心理學的理論，透過七感的輸入調整幫助孩子大腦神經對於各類刺激做出合適的反應，進而促進孩子大腦健全發展，職能治療師常透過感統來改善孩子常跌倒、力道控制不得宜、衝動、情緒起伏大、專注力差、學習障礙、行為問題、語言發展遲緩、坐立不安、感到沮喪、具有攻擊傾向、與別人談話時會靠得過近等問題。我們教您如何不必花大錢促進感統，有趣又有用！適用年齡廣泛，隨時可以和老師討論，透過感覺輸入量的調整，量身打造最適合孩子的遊戲。學員超過2500名，深受老師、保母、家長們的喜愛。
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open(
                                "https://kid-pro.com/product/si-2-12"
                              );
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/86a6122a-e1b9-4899-f15d-1f0bba1b0b00/keepOrigin"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/ae3-1/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          onClick={(e) => {
                            collapseToggle(e, 3);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童 注意力與執行力訓練課程
                          </Title>
                          <Title level={4} className={style.titleH4_2}>
                            原價：5800元
                          </Title>
                          <Title level={4} className={style.titleH3_2}>
                            團購價：最低3折 1740元
                          </Title>
                          {/* <Title
                            level={4}
                            className={`${style.titleH4_2} ${style.titleH4_9}`}
                          >
                            教具包預購中，預計02/03起依訂單順序出貨
                          </Title> */}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-3`}
                      >
                        　　注意力與執行功能常影響孩子學習表現以及生活品質，研究證實我們可以透過一系列相關遊戲，我們可以幫助孩子提供注意力與執行功能，本課程適用3-12歲兒童家長與幼教人員，內容將介紹何謂5大注意力、何謂3大執行功能、並提供您一套教具，透過簡單又好玩的教具，幫助您與孩子一起玩注意力、執行功能的訓練遊戲，我們已經根據孩子年齡設計難度，您更可以根據孩子的能力調整難度，與孩子一起挑戰難關。一套遊戲可以玩好幾年，透過我們的實際示範影片，完整呈現現實狀況。除了遊戲訓練，也提供在生活中實際提高注意力的方法！學員超過2700名，歡迎一同加入！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/ae3-1");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col
                        className={style.gbColCourseImg}
                        style={{ paddingBottom: "5px" }}
                      >
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/c41c80db-adba-46d3-61e7-7e765092b500/keepOrigin"
                          onClick={() => {
                            window.open(
                              "https://kid-pro.com/product/oral-2-12/"
                            );
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          onClick={(e) => {
                            collapseToggle(e, 4);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童/成人 玩美臉型自信開口
                          </Title>
                          <Title level={4} className={style.titleH4}>
                            全方位口肌訓練課程
                          </Title>
                          <Title level={4} className={style.titleH4_2}>
                            原價：8800元
                          </Title>
                          <Title level={4} className={style.titleH3_2}>
                            團購價：最低3折 2640元
                          </Title>
                          {/* <Title
                            level={4}
                            className={`${style.titleH4_2} ${style.titleH4_9}`}
                          >
                            教具包預購中，預計02/03起依訂單順序出貨
                          </Title> */}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-4`}
                      >
                        　　本課程由對口肌訓練專家王亦群語言治療師設計，適合大人與小孩，耳鼻喉科、牙科醫師、家長、希望臉型更玩美的朋友。有健康的口肌對於健康十分重要，更幫助您的臉型漸趨玩美。透過口腔與臉部肌肉的訓練，可以從根本改善臉型、口呼吸，咬字發音、咀嚼、打呼、流口水等等問題。
                        我們幫每位學員準備了價值超過1200元的專業教具，您只要跟著老師的影片一步步練習，持之以恆，可以鍛鍊口腔與臉部肌肉。解決因口腔肌肉力量不足所產生的一系列問題，讓臉型更美觀、更健康自信。2023年7月上架已超過650名學員搶購！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open(
                                "https://kid-pro.com/product/oral-2-12"
                              );
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>

                {/* 揪團區塊 */}
                {/* <Col className={style.gbMain} style={{ padding: 24 }}>
                  <Row style={{ marginInline: 0 }}>
                    <Col
                      className={style.memberTableCol}
                      style={{ background: "#FFF" }}
                    >
                      <div className={style.memberDescriptDiv}>
                        <Title level={3} className={style.titleH3_6}>
                          自己揪團
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className={`${style.gbRowAntButton}`}
                    style={{ top: 0, marginInline: 0, background: "#FFF" }}
                  >
                    <AntButton
                      className={style.gbAntButton}
                      onClick={(e) => {
                        e?.preventDefault();
                        if (!loginToken) {
                          handleShow();
                        } else {
                          setGbPage("CreateGroupPage");
                        }
                      }}
                    >
                      揪新團＋
                    </AntButton>
                  </Row>
                </Col> */}

                <GroupBuyListTeamsComponent
                  gbPage={gbPage}
                  setGbPage={setGbPage}
                ></GroupBuyListTeamsComponent>
              </Col>
              <Row className={style.gbBottomRow0}>
                <Col className={style.gbBottomRow0Col}></Col>
              </Row>
              <Row className={style.gbBottomRow1}>
                <Col className={style.gbBottomRow1Col}></Col>
              </Row>
              <Row className={`${style.gbRowNote}`}>
                <ul className={style.gbUlNote}>
                  {/* <li className={`${style.gbLiNote}`}>
                    最後開團時間為3/7，3/7之後仍可跟團至3/14，請把握時間！
                  </li> */}
                  <li className={`${style.gbLiNote}`}>
                    本活動在組團時只會進行授權，並不會請款。您的卡號將會安全的保存在第三方刷卡公司，只有在成團時才會請款並且正式完成訂單，完成訂單後您馬上就可以觀看課程了！
                  </li>
                  <li className={`${style.gbLiNote}`}>
                    由於您無法重複購買同一門課程，當您加入多個團購，其中一團成團時，其他未成單的團當中，若和已成的團有重複的課程，這些未成的團，都會自動退出，您不用擔心會重複購課。
                  </li>
                </ul>
              </Row>
              <Row className={style.gbBottomRow1}>
                <Title level={3} className={style.titleH3_5}>
                  已經參加了嗎？
                  <span className={style.titleH3_5Span} onClick={handleShow}>
                    登入
                  </span>
                </Title>
              </Row>
              <div style={{ padding: "24px" }}></div>
            </Col>
          </Row>
        </Container>
      ) : gbPage === "CreateGroupPage" && !!loginToken ? (
        <GroupBuyCreateGroup
          gbPage={gbPage}
          setGbPage={setGbPage}
          pidSelectedArray={pidSelectedArray}
          setPidSelectedArray={setPidSelectedArray}
          userProductsArray={userProductsArray}
          setUserProductsArray={setUserProductsArray}
          teamId={teamId}
          setTeamId={setTeamId}
          orderNum={orderNum}
          setOrderNum={setOrderNum}
        ></GroupBuyCreateGroup>
      ) : gbPage === "CheckoutPageCreateTeam" && !!loginToken && !teamId ? (
        <GroupBuyCheckoutCreateTeam
          gbPage={gbPage}
          setGbPage={setGbPage}
          pidSelectedArray={pidSelectedArray}
          setPidSelectedArray={setPidSelectedArray}
          teamId={teamId}
          setTeamId={setTeamId}
          orderNum={orderNum}
        ></GroupBuyCheckoutCreateTeam>
      ) : gbPage === "CheckoutPageAddTeam" && !!loginToken && !!teamId ? (
        <GroupBuyCheckoutAddTeam
          gbPage={gbPage}
          setGbPage={setGbPage}
          userProductsArray={userProductsArray}
          setUserProductsArray={setUserProductsArray}
          teamId={teamId}
          setTeamId={setTeamId}
          orderNum={orderNum}
        ></GroupBuyCheckoutAddTeam>
      ) : (
        <></>
      )}
      <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken}></Popup>
      <Footer></Footer>
    </>
  );
}

export default GroupBuy;
